export default [
	{
		path: "/test",
		component: () => import("@/views/test/WebNN"),
		meta: { layout: "WideLayout" }
    },
	{
		path: "/test/webnn/ic",
		component: () => import("@/views/test/WebNNIC"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/webnn/sam",
		component: () => import("@/views/test/WebNNSAM"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/webnn/sd",
		component: () => import("@/views/test/WebNNSD"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/webnn/hps",
		component: () => import("@/views/test/WebNNHPS"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/webnn/fs",
		component: () => import("@/views/test/WebNNFS"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/webnn/bs",
		component: () => import("@/views/test/WebNNBS"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/webnn/whisper",
		component: () => import("@/views/test/WebNNWhisper"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/webnn/detection",
		component: () => import("@/views/test/WebObjectDetetion"),
		meta: { layout: "WideLayout" }
	},
	{
		path: "/test/webnn/convertLtoB",
		component: () => import("@/views/test/WebNNConverter"),
		meta: { layout: "WideLayout" }
	},
];
