import { createApp } from "vue";
import App from "./App.vue";
import { router } from "@/routes/index.js";
import { i18n } from "@/i18n/index.js";
import store from "@/store/index.js";

/**
 * Bootstrap 설정
 */
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import { GesturePlugin } from '@vueuse/gesture'
import Vue3Toastify from 'vue3-toastify';

// ---------------------------------
// app 선언
// ---------------------------------
const app = createApp(App);
app.use(router);              // 라우터 사용
app.use(store);               // vuex 사용
app.use(i18n);                // 다국어 사용
app.use(BootstrapVue3)
app.use(GesturePlugin)
app.use(Vue3Toastify);
app.config.devtools = true
app.mount("#app");
