import { createStore } from "vuex";
import site from "@/store/site.js";

export default createStore({
  modules: {
    site,
  },
  state () {
    return {
      depth1ImageData: {},
      depth2ImageData: {},
      cache: true,
      devMode: false,
      darkTheme: (localStorage.getItem('darkTheme') || 'true') == "true" ? true : false,
      dotpadCellType: localStorage.getItem('dotpadCellType') || 'D3',
      enableSR: (localStorage.getItem('enableSR') || 'true') == "true" ? true : false,
      enableAudio: (localStorage.getItem('enableAudio') || 'true') == "true" ? true : false,
      provider: localStorage.getItem('provider') || "webgpu",
      device: localStorage.getItem('device') || "gpu",
    }
  },
  mutations: {
    setCache(state, value) {
      state.cache = value;
    },
    setDevMode(state, value) {
      state.devMode = value;
    },
    setDarkTheme(state, value) {
      state.darkTheme = value;
      localStorage.setItem('darkTheme', value);
    },
    setDotpadCellType(state, value) {
      state.dotpadCellType = value;
      localStorage.setItem('dotpadCellType', value);
    },
    setEnableSR(state, value) {
      state.enableSR = value;
      localStorage.setItem('enableSR', value);
      console.log(value)
    },
    setEnableAudio(state, value) {
      state.enableAudio = value;
      localStorage.setItem('enableAudio', value);
    },
    setProvider(state, value) {
      state.provider = value;
      localStorage.setItem('provider', value);
    },
    setDevice(state, value) {
      state.device = value;
      localStorage.setItem('device', value);
    },
    setDepth2ImageData(state, value) {
      state.depth2ImageData = value;
    },
  }
});
